.border-content {
    display: grid;
    align-items: center;
}

.d-flex {
    gap: 20px;
}

.small {
    font-size: smaller;
}

body {
    background-color: #FFEBCD;
    /*D3E0EA*/
}

.header {
    background-color: #F6F1F1 !important;
}

.message {
    white-space: pre-line;
    /* This ensures newlines are respected */
}

.card {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
}

.fade-in {
    opacity: 0;
    /* Start with opacity 0 */
    animation: fadeInAnimation 1s ease-in forwards;
    /* Animation properties */
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}